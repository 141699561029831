<template>
  <div>
    <h4>PREGUNTAS FRECUENTES
      <!-- <img src="@/assets/images/wh.png"> -->
    </h4>
    <div class="faq_list">
      <div class="flex fd-c">
        <ul class="hotel-li flex ai-c">
          <li
            v-for="(item,index) in navData.list"
            :key="index"
            :class="navData.index === index ? 'select-item under-line': 'select-item'"
            @click="goAnchor(item.link,index)"
          >
            <a>{{ item.name }}</a>
          </li>
        </ul>
        <div id="containerList">
          <div v-if="navData.index === 0" id="#selector1" class="container">
            <!-- <h3 class="tit" :class="navData.index === 0 ? 'select-item' : ''">Top Questions</h3> -->
            <el-collapse v-model="activeNames" @change="handleChange">
              <el-collapse-item v-for="(item, index) in faqList" :id="item.id" :key="index" :title="item.title" :name="item.name">
                <div v-html="item.dec" />
              </el-collapse-item>
            </el-collapse>
          </div>
          <div v-if="navData.index === 1" id="#selector2" class="container">
            <!-- <h3 class="tit" :class="navData.index === 1 ? 'select-item' : ''">Loan</h3> -->
            <el-collapse v-model="activeNames" @change="handleChange">
              <el-collapse-item v-for="(item, index) in faqList2" :id="item.id" :key="index" :title="item.title" :name="item.name">
                <div v-html="item.dec" />
              </el-collapse-item>
            </el-collapse>
          </div>
          <div v-if="navData.index === 2" id="#selector3" class="container">
            <!-- <h3 class="tit" :class="navData.index === 2 ? 'select-item' : ''">Repayment</h3> -->
            <el-collapse v-model="activeNames" @change="handleChange">
              <el-collapse-item v-for="(item, index) in faqList3" :id="item.id" :key="index" :title="item.title" :name="item.name">
                <div v-html="item.dec" />
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeNames: ['1'],
      faqList: [
        {
          name: '1',
          id: 'question-1',
          title: '1.¿Cómo puedo solicitar mi primer préstamo?',
          dec: 'Entre a la App y regístrese con su número telefónico y complete los datos básicos. Después de la autorización, de clic en el botón de  “CONFIRMAR”, y el préstamo llegará a su cuenta bancaria. '
        },
        {
          name: '2',
          id: 'question-2',
          title: '2.¿Cómo puedo solicitar de nuevo un préstamo?',
          dec: 'Si le rechazaron su última solicitud, puede volver a solicitar después de 30 días; Una vez que se acredite su pago podrá solicitar un segundo préstamo ¡de inmediato! Y esta vez disfrutará de un monto mayor con un costo menor. '
        },
        {
          name: '3',
          id: 'question-3',
          title: '3.¿Cómo tener otro préstamo mientras tengo uno activo?',
          dec: 'Lamentablemente no puede pedir otro préstamo mientras tiene un activo. Debería liquidar su préstamo activo para poder solicitar otro. Eso sí, una vez que liquide, podrá solicitar otro de forma inmedia. '
        },
        {
          name: '4',
          id: 'question-4',
          title: '4.¿Cuánto tiempo tengo para confirmar mi préstamo en la App?',
          dec: ' Le quedan Siete días para confirmar su préstamo. Le recomendamos dar clic en “CONFIRMAR” lo antes posible. Cuando se convierta en nuestro cliente Premium, disfrutará de un monto mayor con un costo menor. '
        },
        {
          name: '5',
          id: 'question-5',
          title: '5.¿Cuánto tiempo tarda en depositar?',
          dec: 'Procesaremos su solicitud lo antes posible. Una vez aprobada tu solicitud, el depósito se completará en unos minutos. Sin embargo, debido a la diferencia en el horario laboral de los bancos , puede haber un retraso en su transferencia. No se preocupe, si tiene circunstancias especiales, puede contactarnos al fondosmiosapp@gmail.com de 9:30 a 18:30 en horario laboral. '
        },
        {
          name: '6',
          id: 'question-5',
          title: '6.¿Qué puedo hacer si falla el depósito?',
          dec: 'Estimado cliente, en este caso puede intentar comprobar si hay un error en el préstamo, o hay un problema con la recepción de su cuenta, y si el estado de su cuenta bancaria es normal. Si tiene circunstancias especiales, puede contactarnos al fondosmiosapp@gmail.com de 9:30 a 18:30 en horario laboral Le proporcionaremos la información más detallada y le brindaremos servicios más personalizados. '
        },
        {
          name: '7',
          id: 'question-5',
          title: '7.¿Cómo usan la información que lleno en la solicitud de préstamo? ',
          dec: ' FondosMíos usa sus respuestas para determinar si es candidato a un préstamo de nuestra App. Toda su información se encuentra segura y protegida. Nunca proporcionaremos su información a terceros sin su permiso. Puede leer nuestro Aviso de Privacidad dentro de la App. '
        }
      ],
      faqList2: [
        {
          name: '1',
          id: 'question-1',
          title: '1.Me rechazaron mi préstamo, ¿por qué?',
          dec: 'Lamentamos que su solicitud haya sido rechazada; esto significa que no podrá utilizar nuestros servicios por el momento. ¡No se preocupe! En esta ocasión no fue candidato para un préstamo, pero esperamos que pronto se una a FondosMíos. En cuanto sea elegible de nuevo, la App le mandará una notificación a su celular para realizar una nueva solicitud. '
        },
        {
          name: '2',
          id: 'question-2',
          title: '2.Realicé el pago a tiempo (por adelantado), y me rechazaron mi próximo préstamo, ¿por qué?',
          dec: 'Lamentamos que su solicitud haya sido rechazada. Hay varias razones por las que esto sucede. Para tener una mejor comprensión de lo sucedido, puede contactarnos al fondosmiosapp@gmail.com de 9:30 a 18:30 en horario laboral, indicando nombre y telefono registrado para que podamos brindarle un servicio personalizado.  '
        },
        {
          name: '3',
          id: 'question-3',
          title: '3.Realicé el pago, sin embargo, en la App no ha disminuido la cantidad pendiente de pago, ¿qué hago? ',
          dec: 'Lamentamos que se haya encontrado con este problema. Favor de tener más paciencia, porque los pagos en efectivo pueden tardar hasta 24 horas en verse reflejados. Y las transferencias interbancarias pueden tardar hasta 72 horas. Si pasado ese tiempo, no ve su pago reflejado, puede contactarnos al fondosmiosapp@gmail.com de 9:30 a 18:30 en horario laboral. '
        },
        {
          name: '4',
          id: 'question-4',
          title: '4.¿Qué hago si mi cuenta se bloquea y no puedo seguir solicitando?',
          dec: 'Lamentamos que se haya encontrado con este problema. Favor de verificar su red o vuelva a descargar la App para intentar completar su información.'
        },
        {
          name: '5',
          id: 'question-5',
          title: '5.¿Cómo puedo aumentar el monto de préstamo?',
          dec: 'Procesaremos su solicitud lo antes posible. Una vez aprobada tu solicitud, el depósito se completará en unos minutos. Sin embargo, debido a la diferencia en el horario laboral de los bancos , puede haber un retraso en su transferencia. No se preocupe, si tiene circunstancias especiales, puede contactarnos al fondosmiosapp@gmail.com de 9:30 a 18:30 en horario laboral. '
        },
        {
          name: '6',
          id: 'question-6',
          title: '6.¿Cómo puedo aumentar el monto de préstamo?',
          dec: 'Nuestro sistema evalúa el monto según su crédito. Por favor, mantenga un historial crediticio bueno y constante, lo que le ayudará a aumentar el monto de su préstamo. Sin embargo, estos datos se basan en el puntaje integral del sistema y no se permiten factores humanos. Esperamos su comprensión.'
        },
        {
          name: '7',
          id: 'question-7',
          title: '7.¿Qué hago si mi pago no se refleja en la App? ',
          dec: 'Lamentamos que se haya encontrado con este problema. Normalmente el pago se reflejará después de un día laborable si paga en línea, y dentro de 72 horas si paga en efectivo. Si aún no se refleja su pago, por favor nos envíe por correo: dinercolsac@gmail.com de 9:00 a 18:00 en horario laboral. '
        }
      ],
      faqList3: [
        {
          name: '1',
          id: 'question-1',
          title: '1.¿Cómo me registro en FondosMíos?',
          dec: 'Deberá introducir su número de teléfono móvil, hacer clic para obtener código de verificación y, a continuación, introducir el código de verificación que reciba completar su registro. '
        },
        {
          name: '2',
          id: 'question-2',
          title: '2.¿Qué hago si no recibo el código de verificación? ',
          dec: 'Ofrecemos dos formas de verificar, el código de verificación por SMS y por llamada de voz. Asegúrese de que su teléfono funciona bien y de que la señal es estable. Puede haber algunos retrasos cuando el servidor de su operador esté ocupado. Si no recibe el código de verificación, inténtelo más tarde.  '
        },
        {
          name: '3',
          id: 'question-3',
          title: '3.¿No se puede hacer reconocimiento facial? ',
          dec: 'Para la verificación de la cara, en primer lugar, asegúrese de que ha activado los permisos de la cámara, si no, por favor, actívelo en la configuración de su teléfono móvil. Cuando realice el reconocimiento facial, intente elegir un entorno luminoso y asegúrese de que el dispositivo utilizado está en condiciones buenas y en un buen entorno de red. '
        },
        {
          name: '4',
          id: 'question-4',
          title: '4.¿No puedo iniciar sesión en la aplicación/la aplicación está atascada/no puedo acceder a mi cuenta?  ',
          dec: 'Asegúrese de que su número de móvil y su código de verificación es correcta.  '
        },
        {
          name: '5',
          id: 'question-5',
          title: '5.¿Cómo puedo cambiar mi nombre?',
          dec: 'Considerando la seguridad de su información, no se puede realizar los cambios del nombre en este momento. Sin embargo, para realizar una solicitud de préstamo, deberá asegurarse de que su nombre coincida con su nombre completo en su documento de identificación (INE / IFE). '
        },
        {
          name: '6',
          id: 'question-6',
          title: '6.¿Cómo puedo cambiar mi número de teléfono?',
          dec: 'Considerando la seguridad de su información, no se puede realizar los cambios en su número de teléfono en este momento.  '
        }
      ],
      faqList4: [
        {
          name: '1',
          id: 'question-1',
          title: "1.Q: Why can't I submit when I fill in how much information?",
          dec: 'A:When you experience any technical issues with the PesoGo VIP app, please fill in the feedback.'
        },
        {
          name: '2',
          id: 'question-2',
          title: '2.Q: Can I change my telephone number and ID card information?',
          dec: 'A:To ensure the authenticity and security of identity information, we are currently unable to facilitate changes to telephone numbers and ID cards.'
        }
      ],
      navData: {
        index: 0,
        list: [
          {
            name: 'La solicitud de préstamo',
            link: '#selector1'
          },
          {
            name: 'Los casos excepcionales',
            link: '#selector2'
          },
          {
            name: 'Otros problemas',
            link: '#selector3'
          }
        ]
      }
    }
  },
  methods: {
    handleChange(val) {
      console.log(val)
    },
    goAnchor(selector, index) {
      this.navData.index = index
      const section = document.getElementById(selector)
      var element = document.getElementById('containerList')
      console.log(section, element)
      if (section && element) {
        const children = element.children
        let topOffset = 0
        for (let i = 0; i < children.length; i++) {
          if (children[i] === section) {
            break
          }
          topOffset += children[i].offsetHeight
        }
        element.scrollTo({ top: topOffset, behavior: 'smooth' })
      }
    }
  }

}
</script>

<style lang="scss" scoped>
h4{
  width: 100%;
  padding:100px calc((100% - 1100px) / 2);
  // background: linear-gradient(270deg, #C54347 0%, #7149BC 100%);
  background: url('~@/assets/images/loanImg/faq_bg.png') no-repeat ;
  background-size: 100% auto ;
  color: white;
  line-height: 120px;
  font-size: 35px;
  text-align: center;
  img{
    float: right;
    width: 100px;
    margin: 10px 120px 0 0;
  }
}
.faq_list{
  background: #F3F3F3;
  padding: 0 200px 60px;
  img{
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 550px;
  }
  #containerList{
    margin-top: 50px;
    background: #fff;
    flex: 3.7;
    padding: 40px 50px;
    border-radius: 15px;
    // overflow: scroll;
    // height: 80vh;
  }
  .container{
    // padding: 0 calc((100% - 1200px) / 2) 50px;
    margin-bottom: 40px;
    .tit{
      font-size: 25px;
      line-height: 50px;
      margin-bottom: 20px;
    }
    .select-item{
      color: #007BFF !important;
    }
  }
  .hotel-li {
    padding: 0 80px;
    padding-top: 50px;
    justify-content: space-between;
    li {
      width: 30%;
      font-size: 14px;
      text-align: center;
      line-height: 45px;
      white-space:nowrap;
      background: #fff;
      border-radius: 25px;
      cursor: pointer;
      a{
        color: #333333;
        text-decoration: none;
      }
      &.under-line{
        color: $color-6E60F9;
        background: #3C50FB;
        a{
          font-weight: bold;
          color: #fff;
          position: relative;
          // &::after{
          //   position: absolute;
          //   left: 50%;
          //   transform: translateX(-50%);
          //   bottom: -15px;
          //   content: '';
          //   width: 100%;
          //   height: 3px;
          //   background: #3286FF;
          // }
        }
      }
    }
  }
}
:deep(.el-collapse){
  border-bottom: none;
}
:deep(.el-collapse-item__header) {
  background-color:#EEEEEE;
  padding-left: 20px;
  margin-bottom: 30px;
}
:deep(.el-collapse-item__content) {
  padding: 0px 350px 20px 20px;
}
@media only screen and (max-width: 665px){
  h4{
    font-size: 12px;
    line-height: 50px;
    padding: 0 !important;
    img{
      width: 40px;
      margin: 5px 30px 0 0;
    }
  }
  .faq_list{
    min-width: calc(100vw);
    padding: 0 !important;
    .container{
      img{
        width: 250px;
        height: 200px;
        margin: 10px auto;
      }
    }
    .hotel-li{
      padding: 20px 10px;
      flex-direction: column;
      li{
        width: 80%;
        margin-bottom: 20px;
      }
      .select-item{
        a{
          font-size: 12px;
        }
      }
    }
  }
  #containerList{
    margin-top: 0 !important;
    padding: 40px 20px !important;
  }
  :deep(.el-collapse-item__header) {
    padding-left: 20px;
    font-size: 12px !important;
    line-height: 24px !important;
    height: 50px !important;
  }
  :deep(.el-collapse-item__content) {
    padding: 20px 20px !important;
    font-size: 12px !important;
  }
}
</style>
